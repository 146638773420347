import { useEffect } from "react";

import { usePiano } from "./use-piano";

/**
 * Require Piano hook
 *
 * Display or redirect to login page if not logged in. Returns Piano context.
 * @param {'redirect'|'modal'|'login-modal'|'register-modal'} action - Action to take if not logged in
 * @param {string} redirectUrl - URL to redirect to if not logged in
 */
export function useRequirePiano(action = "redirect", redirectUrl = "/account/") {
  const piano = usePiano();

  // If auth.user is false that means we're not logged in and should redirect.
  useEffect(() => {
    if (piano.isLoggedOut) {
      if (
        action === "modal" ||
        action === "login-modal" ||
        window.location.pathname === redirectUrl
      ) {
        piano.showLoginModal();
      } else if (action === "register-modal") {
        piano.showRegisterModal();
      } else {
        window.location.pathname = redirectUrl;
      }
    }
  }, [piano.isLoggedOut]);

  return piano;
}

export default useRequirePiano;
