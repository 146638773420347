import Cookies from "js-cookie";

import { parseJwt } from "@sciam/shared";

import { PIANO_ENVIRONMENTS, PIANO_PREPROD, PIANO_PROD, PIANO_SANDBOX } from "./environments";

/**
 * Gets Piano's `__utp` cookie (JSON Web Token)
 *
 * @TODO Test me
 * @see https://docs.piano.io/piano-cookie-descriptions/#the-user-token-cookie-piano-io
 * @returns {string} Piano ID JWT
 */
const getUtpCookie = () => document.cookie.match(/(?:^|;)\s*__utp\s*=\s*([^;]+)/)?.[1] || "";

/**
 * Gets Piano User object from JWT
 *
 * This can be used to get preliminary user data before the Piano script is initialized.
 *
 * While a returned user object is _not_ guaranteed to be accurate, a `null` response _does_ guarantee
 * that the user is not logged in.
 *
 * @param {string} [utp] Piano ID JWT
 * @returns {Record<string, string|number|boolean|undefined> | null} Piano User object or null
 */
const getPianoIdUserDirty = (utp) => {
  utp = utp || getUtpCookie();
  const jwt = utp ? parseJwt(utp) : null;
  if (!jwt) return null;

  return {
    ...jwt,

    // Piano adds these aliases to the tp user object
    firstName: jwt.given_name,
    lastName: jwt.family_name,
    uid: jwt.sub,

    // Ensure that the token can't be used to impersonate a user
    confirmed: undefined,
    valid: undefined,
    dirty: true,
  };
};

/**
 * Load custom fields / extended user data
 * @param {string} [formName] Defaults to the "My Account" form, per Piano docs
 * @returns {Promise<Record<string, any>>} Promise resolves to extended user data
 */
export function getExtendedUser(formName) {
  return new Promise((resolve, reject) => {
    try {
      window.tp.pianoId.loadExtendedUser({
        extendedUserLoaded: (data) => resolve(data),
        formName,
      });
    } catch (error) {
      reject();
    }
  });
}

export const getExtraUserInfo = async () => {
  const utp = getUtpCookie();
  const ENDPOINT = "/platform/api/v2/accounts/get-user-info/";

  const response = await fetch(ENDPOINT, {
    headers: { Authorization: `Bearer ${utp}` },
  })
    .then((resp) => resp.json())
    .catch((error) => (console.error(error), null));

  return response;
};

/**
 * Detects piano debug mode and syncs it to localStorage
 *
 * Modes:
 * - `"log"` - Verbose console logging from Piano TP object
 * - `"prod"` - Verbose logging + force production environment
 * - `"preprod"` - Verbose logging + force pre-production environment
 * - `"sandbox"` - Verbose logging + force sandbox environment
 * - `false` - Disable Piano debug mode
 * @returns {"prod"|"sandbox"|"preprod"|"log"|false}
 */
const getPianoDebugMode = () => {
  if (typeof window === "undefined") return false; // Don't run on server
  const ls = localStorage;
  const pianoDebugKey = "piano-debug";

  // Check for debug query string
  // example matches: debug=piano, debug=piano-prod, debug=piano-log, debug=piano-reset
  const debugQuery = location.search.match(/debug=piano(?:-(prod|preprod|sandbox|log|reset))*/);

  // Use localStorage if no debug query string is present
  if (!debugQuery?.[0]) {
    // @ts-ignore: debug will only ever be "log", "prod", or false
    return ls.getItem(pianoDebugKey) || false;
  }

  const debugFlag = /** @type {"prod"|"preprod"|"sandbox"|"log"|"reset"} */ (
    debugQuery[1] || "log"
  );

  // Remove debug flag from localStorage if query flag is "reset"
  if (debugFlag === "reset") {
    ls.removeItem(pianoDebugKey);
    return false;
  }

  // Set debug flag in localStorage
  ls.setItem(pianoDebugKey, debugFlag);
  return debugFlag;
};

/** @returns {Boolean} */
const verifyPianoEnvironment = (url = "") => {
  if (typeof window === "undefined") return false; // Don't run on server
  const utp = getUtpCookie();
  const jwt = utp ? parseJwt(utp) : null;

  // User may be logged in
  if (jwt) {
    // aid is the last 10 characters of the Piano script URL
    const aid = url.slice(-10);
    // User cookie is for the correct Piano environment
    return jwt?.aud === aid;
  }

  // User is not logged in
  return true;
};

/**
 * Script URL for Piano
 * @see https://docs.piano.io/getting-started-module-2-setting-up-piano-scripts/
 */
const pianoScriptUrl = () => {
  const PIANO_URLS = /** @type {const} */ ({
    prod: PIANO_PROD.sdkUrl,
    preprod: PIANO_PREPROD.sdkUrl,
    sandbox: PIANO_SANDBOX.sdkUrl,
  });

  // Don't run this serverside since we won't even know the url
  if (typeof window === "undefined") return;

  const hostname = window.location.hostname;

  // 1. Prod domains should always load the prod script.
  if (hostname.match(/^(?:www|blogs)\./)) {
    return PIANO_URLS.prod;
  }

  // 2. If a valid debug mode is set, use that
  const debugMode = getPianoDebugMode();
  if (debugMode && debugMode !== "log") {
    return PIANO_URLS[debugMode];
  }

  // 3. Everything else, e.g. main-www or beta envs, will default to sandbox
  return PIANO_URLS.sandbox;
};

/**
 * Get Piano Environment Object
 */
const getPianoEnv = () => {
  const url = pianoScriptUrl();
  return PIANO_ENVIRONMENTS.find((env) => env.sdkUrl === url);
};

function isProdSubdomain(location = window.location) {
  return !!location.hostname.match(/^(?:www|blogs)\./);
}

function isMainWWWSubdomain(location = window.location) {
  return !!location.hostname.match(/^(?:main-www)\./);
}

/**
 * Determine if Piano is in production mode
 *
 * Production mode is `true` in any of the following cases:
 * - The hostname is a production subdomain (www.scientificamerican.com, blogs.scientificamerican.com)
 * - The user's `piano-debug` flag is set to `prod`
 * @deprecated Only here to maintain parity with piano-mura.js. Use `getPianoEnv()` instead.
 */
function isPianoProd() {
  if (typeof window === "undefined") return false; // No window, no Piano
  const location = window.location;
  const isProdSciAmSubdomain = isProdSubdomain(location);
  const pianoDebug = getPianoDebugMode();
  /* 1. check for prod override or match prod subdomains */
  /* 2. check for sandbox override and match prod-ish subdomains */
  return isProdSciAmSubdomain || pianoDebug === "prod";
}

/**
 * Promisified version of Piano API call
 * @returns {Promise<any>}
 */
const callPianoApi = (endpoint, params) =>
  new Promise((resolve, reject) => {
    try {
      window.tp.api.callApi(endpoint, params, (response) => resolve(response));
    } catch (err) {
      reject(err);
    }
  });

/**
 * The Universal offer is a Piano offer that contains
 * All terms. It's useful for working with startCheckout
 * without having to mess with specific offer IDs.
 */
function getUniversalOffer() {
  return getPianoEnv().defaultOffer;
}

/**
 * The Winback offer is a Piano offer that contains two dynamic terms.
 */
function getWinbackOffer() {
  return getPianoEnv().winbackOffer;
}

/**
 * The Winback term is a dynamic term where renewal will be set for 2 years. After the renewal, the subscription will then again renew to 1 year.
 * @returns {string}
 */
function getWinbackTerm1Year() {
  return getPianoEnv().terms.winbackTerm1Year;
}

/**
 * The Winback term is a dynamic term where renewal will be set for 1 year. After the renewal, the subscription will then again renew to 1 year.
 * @returns {string}
 */
function getWinbackTerm2Year() {
  return getPianoEnv().terms.winbackTerm2Year;
}

/**
 * Universal Piano Product Checkout
 *
 * Initiates checkout flow by opening the Piano checkout modal.
 * User will see a registration form if they are not logged in.
 *
 * @param {string} offerId ID for a group of Terms
 * @param {string} termId Piano ID of product/timefame being shown
 * @param {string|undefined} [promoCode] Optional promo code
 */
const startPianoCheckout = (offerId, termId, promoCode) => {
  const tp = window.tp || {};

  tp.offer?.startCheckout({
    offerId,
    termId,
    promoCode: promoCode || undefined,
  });
};

// Piano runs its purchase across pageviews during
// which the hasSub info will be unreliable. Hackily
// paper over this with localStorage.
const activePurchaseKey = "_active_piano_purchase";

const pianoActivePurchase = {
  start: () =>
    Cookies.set(activePurchaseKey, "1", {
      // 30 seconds
      expires: (1 / 24 / 60) * 0.5,
    }),
  end: () => Cookies.remove(activePurchaseKey),
  isActive: () => Cookies.get(activePurchaseKey) === "1",
};

export {
  callPianoApi,
  getPianoDebugMode,
  getPianoEnv,
  getPianoIdUserDirty,
  getUniversalOffer,
  getUtpCookie, getWinbackOffer,
  getWinbackTerm1Year,
  getWinbackTerm2Year, isPianoProd,
  pianoActivePurchase,
  pianoScriptUrl,
  startPianoCheckout,
  verifyPianoEnvironment
};
