import Cookies from "js-cookie";
import { callPianoApi } from "./utils";

let log = (...args) => {};
if (typeof window !== "undefined" && window?.location.search.match(/debug=access/)) {
  log = console.debug.bind(window.console);
}

/** @typedef {"ADFREE"|"DIGITAL"|"DIGPRINT"|"REGONLY"|"UNLMTD"} ResourceId */
/** @typedef {{ rid: ResourceId, aid: string, name: string }} Resource */

/**
 * Utility to fetch the Piano Resources a user has access to.
 * @returns {Promise<Resource[]>} Promise - Resolves with an array
 * of Resource objects the user has access to
 */
export async function getUserResourceList(catchErrors = true) {
  let resources;
  try {
    const accessList = await callPianoApi("/access/list");
    resources = accessList?.data?.map?.(({ resource }) => resource).filter(Boolean) || [];
  } catch (e) {
    if (catchErrors) {
      return [];
    }
    throw e;
  }

  return resources;
}

/**
 * Check if a user has access to a specific resource.
 * @type {(resources: Resource[], ...rids: ResourceId[]) => boolean}
 */
export const resourceListIncludesRid = (resources = [], ...rids) =>
  resources?.some?.(({ rid }) => rids.includes(rid)) || false;

/**
  * Get the primary resource from a list of resources.
  * @type {(resources: Resource[]) => Resource | null}
  */
export const getPrimaryResource = (resources = []) => {
  /** @type {ResourceId[]} */
  const hierarchy = ["UNLMTD", "DIGPRINT", "DIGITAL", "ADFREE", "REGONLY"];

  for (let rid of hierarchy) {
    const resource = resources.find((r) => r.rid === rid);
    if (resource) return resource;
  }

  // This should never happen, but if it does, return the first resource
  return resources[0] || null;
};

/* Utility function to determine if user has a paid subscription.
 * @returns {Promise} Promise - Resolves with an object that
 *      tells us if they have a paid subscription
 */
export async function userHasAccess(cache = true) {
  // @ts-ignore
  if (cache && window._userHasSub !== undefined) {
    // @ts-ignore
    return { hasSub: window._userHasSub };
  }

  const resources = await getUserResourceList();
  const hasSub = resourceListIncludesRid(resources, "DIGITAL", "DIGPRINT", "UNLMTD");

  // @ts-ignore
  window._userHasSub = hasSub;
  return { hasSub };
}

/**
 * Find the content entry in data layer and return the article's release date, if there is one.
 * Note, since content is pushed after page load this _might_ be a race condition. Use accordingly.
 * @returns {string} Publication date
 */
function getPageReleaseDate() {
  if (typeof window === undefined) {
    // TODO SSR
    return;
  }

  const dlEntry = window?.dataLayer.find((entry) => entry?.content?.publishedAtDate);
  if (dlEntry) {
    return dlEntry.content.publishedAtDate;
  }

  // @HACK use the raw data to avoid annoying race conditions.
  // Shouldn't matter for hopper since this is re-evaluated, but is a problem
  // for Static Toolkit. @TODO: inline the data layers everywhere.
  // @ts-ignore
  if (window?.__NEXT_DATA__) {
    // @ts-ignore
    return window?.__NEXT_DATA__?.props?.pageProps.dataLayerContent?.publishedAtDate;
  }
  // @ts-ignore
  if (window?.__DATA__) {
    // @ts-ignore
    return window?.__DATA__?.initialData?.dataLayerContent?.content?.publishedAtDate;
  }
}

/**
 * Determines if the user has the Inst. Access cookie based on their IP.
 *
 * @see https://sciam.atlassian.net/wiki/spaces/SA/pages/156172289/Institutional+Access
 * @TODO rename to getHasInstitutionalAccess
 * @returns {Boolean} - user should access all content for free
 */
export function hasInstitutionalAccess() {
  if (typeof window === "undefined") {
    return false;
  }

  const publishedDate = getPageReleaseDate();

  // The full details take two up to do API calls to validate
  // and one might have to filter on IP address. Assume it may
  // not be populated on the first render.
  let idpDetails = {};
  try {
    const idpDetailsCookieVal = Cookies.get("idp_details");
    if (idpDetailsCookieVal) {
      idpDetails = JSON.parse(idpDetailsCookieVal);
    }
  } catch (e) {
    console.error(e);
  }

  // Have full details: attempt date based access controls
  if (idpDetails?.BPID) {
    // For known IDP users when there's no known published date, do not paywall
    if (!publishedDate) {
      return true;
    }

    if (idpDetails.active_contracts === null) {
      // We have the BPID but no contracts, which tells me they
      // were a client but don't have any active entitlements.
      log("[access] IDP accesss denied because there are no active entitlements");

      return false;
    } else if (idpDetails.active_contracts === undefined) {
      log("[access] IDP accesss granted because the entitlements are undefined, failing open");
      // If the contracts property isn't provided, we're not filtering
      // on contracts yet/for this client.
      return true;
    }

    // Compare the date to the contract ranges
    const pubDate = new Date(publishedDate);
    let hasAccess = false;
    idpDetails.active_contracts.forEach((contract) => {
      let { to, from } = contract;
      const earliest = new Date(from);
      const latest = new Date(to);
      if (pubDate >= earliest && pubDate <= latest) {
        hasAccess = true;
      }
    });

    log(
      `[access] IDP access ${
        hasAccess ? "GRANTED" : "DENIED"
      } for this page based on its release date`,
      {
        pubDate,
        active_contracts: idpDetails.active_contracts,
      },
    );
    return hasAccess;
  }

  // If idp_details aren't ready yet, check for the "in between" states
  // If a user is in one of these, we know they should have access, we
  // just don't know the constraints just yet. Default to granting access.
  if (Boolean(document.cookie.match("idp_magic="))) {
    log("[access] IDP accesss granted because of a magic word");
    return true;
  }

  if (Boolean(document.cookie.match("ezproxy_bpid="))) {
    log("[access] IDP accesss granted because of a magic work");
    return true;
  }

  const ipAllowlistMatch = Boolean(document.cookie.match(/_pc_instaccess=1/));
  if (ipAllowlistMatch) {
    log("[access] IDP accesss granted on IP alone");
  }
  return ipAllowlistMatch;
}
