// @ts-check
import { clearCheckoutArgs, getCheckoutArgs, getExternalJwt, setCheckoutArgs } from "./utils";

/**
 * This function performs all the logic that used to be handled by the experience.tinypass.com script,
 * along with some overrides to enable Auth0
 *
 * @see https://experience.tinypass.com/xbuilder/experience/load?aid=FU52w9tupu
 * @see https://experience.tinypass.com/xbuilder/experience/load?aid=zb26Lrf5pu
 * @see https://sandbox.tinypass.com/xbuilder/experience/load?aid=I2n3TREbsu
 * @param {import('../environments').PianoEnvironment} envConfig
 */
export function loadExperience(envConfig, hostname = "") {
  const tp = window.tp;
  // For when Auth0 is used as the auth provider
  const enableAuthSync = envConfig.authProvider !== "pianoid";

  const isFullSciamDomain = hostname.endsWith(".scientificamerican.com");
  const isProdSubdomain = isFullSciamDomain && hostname.startsWith("www.");
  const isNonProdSubdomain =
    isFullSciamDomain && !isProdSubdomain && hostname.match(/^(?:main-www|local-www)\./);

  tp.push(["setAid", envConfig.aid]);

  if (isProdSubdomain) {
    tp.push(["setComposerHost", "https://c2.tp.scientificamerican.com"]);
    tp.push(["setPianoIdUrl", "https://id.tp.scientificamerican.com"]);
    // Disabled because it breaks the experience script
    // tp.push(["setEndpoint", "https://vx.tp.scientificamerican.com"]);
  } else {
    tp.push(["setPianoIdUrl", envConfig.pianoIdUrl]);
  }

  tp.push(["setEndpoint", envConfig.endpointUrl]);

  if (isFullSciamDomain) {
    if (isNonProdSubdomain) {
      tp.push(["setCookieDomain", hostname]);
    } else {
      tp.push(["setCookieDomain", "scientificamerican.com"]);
    }
  }

  // Only prod sets this value
  if (envConfig.cxenseSiteId) {
    tp.push(["setCxenseSiteId", envConfig.cxenseSiteId]);
  }

  // This has always been false
  tp.push(["setUseTinypassAccounts", false]);

  if (enableAuthSync) {
    const jwt = getExternalJwt(envConfig.authExternalClientId);
    console.log("[piano] using Piano Auth Sync with external JWT", jwt);

    // Disable Piano ID when using Auth0 as the auth provider
    tp.push(["setUsePianoIdUserProvider", false]);
    tp.push(["setUsePianoIdLiteUserProvider", true]);
    if (jwt) tp.push(["setExternalJWT", jwt]);
  } else {
    tp.push(["setUsePianoIdUserProvider", true]);
  }

  /**
   * Callback executed when a user must login
   * @param {Record<string,any>} params The checkout parameters, can be passed directly into `tp.offer.startCheckout`
   * @param {string} id Possibly an offer id or tracking id?
   * @param {Record<string,any>} idk It's only ever shown up as an empty object in my testing
   */
  function onLoginRequired(params, id, idk) {
    // Don't bother with the redirect, just show the login modal
    if (!enableAuthSync) {
      return false;
    }

    // Save the checkout params for later
    try {
      setCheckoutArgs([params, id, idk]);
      console.log("[piano] saved checkout params", params, id, idk);
    } catch (error) {
      console.error("[piano] error saving checkout params", error);
    }

    const authPage = params.startScreen === "register" ? "/register/" : "/login/";
    window.location.href =
      authPage +
      "?returnTo=" +
      encodeURIComponent(window.location.pathname + window.location.search);

    // (copied from the original script)
    // If we wanted to do this without navigating away from the page, we could do something like this:
    // This is a reference implementation only. your own custom login/registration implementation would
    // need to return the tinypass-compatible userRef inside the callback.
    // showLoginRegistration((tinypassUserRef) => {
    //   tp.push(["setUserRef", tinypassUserRef]);
    //   tp.offer.startCheckout(params);
    // }

    // Prevent an error screen from displaying
    return false;
  }

  /* Callback executed if external checkout has been completed successfully */
  function onExternalCheckoutComplete(event) {
    /* Default behavior is to refresh the page on successful checkout */
    location.reload();
  }

  // The handlers set to `noop` exist to maintain the same function signature
  // as the original experience.tinypass script. It almost definitely has no effect either way.
  const noop = () => {};

  /* checkout related events */
  tp.push(["addHandler", "checkoutComplete", noop]);
  tp.push(["addHandler", "checkoutClose", noop]);
  tp.push(["addHandler", "checkoutCustomEvent", noop]);
  tp.push(["addHandler", "checkoutCancel", noop]);
  tp.push(["addHandler", "checkoutError", noop]);
  tp.push(["addHandler", "checkoutSubmitPayment", noop]);

  /* user login events */
  tp.push(["addHandler", "loginRequired", onLoginRequired]);
  tp.push(["addHandler", "loginSuccess", noop]);

  /* meter related */
  tp.push(["addHandler", "meterExpired", noop]);
  tp.push(["addHandler", "meterActive", noop]);

  tp.push(["addHandler", "experienceExecute", noop]);
  tp.push(["addHandler", "experienceExecutionFailed", noop]);

  /* external checkout related events */
  tp.push(["addHandler", "externalCheckoutComplete", onExternalCheckoutComplete]);

  tp.push([
    "init",
    () => {
      const tp = window.tp;

      // Start immediately
      tp.experience.init();

      // Log the environment info
      const { aid, composerHost, cookieDomain, cxCdnUrl, endpoint, pianoIdUrl } = tp;
      console.log("[piano] env info", {
        aid,
        composerHost,
        cookieDomain,
        cxCdnUrl,
        endpoint,
        pianoIdUrl,
      });

      // If we're logged out or in an auth callback page, we're done here.
      if (
        !tp.user.isUserValid() ||
        location.pathname.match(/account|login|logout|callback|register/)
      ) {
        return;
      }

      const checkoutData = getCheckoutArgs();
      if (checkoutData) {
        const [params, id] = checkoutData;
        tp.offer.startCheckout({
          ...params,
          // offerId: id, // not currently sure if this is correct or useful
        });
        clearCheckoutArgs();
      }
    },
  ]);
}

/**
 * Domain-based overrides for the Piano script
 * @deprecated For reference only. This is now implemented in loadExperience()
 */
export function setIframeDomain(hostname = "") {
  const tp = window.tp;

  // For staging test/envs, be explicit
  if (["main-www.scientificamerican.com", "local-www.scientificamerican.com"].includes(hostname)) {
    tp.push(["setCookieDomain", hostname]);
  } else if (hostname.endsWith(".scientificamerican.com")) {
    // For prod, set it at the top level
    tp.push(["setCookieDomain", "scientificamerican.com"]);
  }

  // Only works on our domain
  if (hostname === "www.scientificamerican.com") {
    // always works because no other script calls it
    tp.push(["setComposerHost", "https://c2.tp.scientificamerican.com"]);

    // ignored because it's set in the experience script
    tp.push(["setPianoIdUrl", "https://id.tp.scientificamerican.com"]);

    // ignored because it's set in the experience script
    // Disabled because this breaks piano
    // tp.push(["setEndpoint", "https://vx.tp.scientificamerican.com"]);
  }
}

export function setPaywallExempt() {
  window.tp.push(["setTags", ["paywall-exempt"]]);
}

/**
 * @param {import('@sciam/shared').DataLayerContent} dataLayerContent
 */
export function setCustomVariables(dataLayerContent) {
  /* Custom Variables to send to Piano */
  [
    ["language", "en"],
    ["primaryCategory", dataLayerContent.primaryCategory || ""],
    ["subCategory", dataLayerContent.subCategory || ""],
    ["subtype", dataLayerContent.subtype || ""],
    ["type", dataLayerContent.type || ""],
    ["contentId", ""],
    ["collectionId", ""],
    ["collectionName", ""],
    ["publishedAtDateTime", dataLayerContent.publishedAtDateTime || ""],
    ["publishedAtDate", dataLayerContent.publishedAtDate || ""],
    ["publishedAtTime", dataLayerContent.publishedAtTime || ""],
    ["updatedAtDateTime", dataLayerContent.updatedAtDateTime || ""],
    ["brand", ""],
    ["authors", dataLayerContent.authors || []],
    ["title", dataLayerContent.title || ""],
    ["tags", ""],
    ["template", ""],
    ["isSyndicated", false],
    ["isPartner", false],
    ["isSponsored", false],
    ["isResalable", false],
    ["containsMedia", ""],
    ["articleDoi", dataLayerContent.articleDoi || ""],
    ["journalIssueName", dataLayerContent.journalIssueName || ""],
    ["wordCount", undefined],
  ].forEach(([key, value]) => window.tp.push(["setCustomVariable", key, value]));
}
